

















































































import { Component, Vue } from 'vue-property-decorator';
import EmergencyFundVideoViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/emergency-fund/emergency-fund-video-view-model';

@Component({
  name: 'EmergencyFundVideo',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class EmergencyFundVideo extends Vue {
  view_model = Vue.observable(
    new EmergencyFundVideoViewModel(this),
  );
}
